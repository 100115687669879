import { useCookieValue } from "@product/scmp-sdk";
import { useSyncedRef } from "@react-hookz/web";
import { useMemo } from "react";

// Reactive by the cookieName, will not be reactive if cookie is modified externally and parse is new
export function useParsedCookie(cookieName, parser, options) {
  const [raw, update, remove] = useCookieValue(cookieName, options);

  // Use latest parser ref to avoid re-run different re-run of the memorized
  const latestParser = useSyncedRef(parser);
  const memorized = useMemo(() => raw && latestParser.current(raw), [latestParser, raw]);
  return {
    remove,
    update,
    value: memorized
  };
}