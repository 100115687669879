interface Scheduler {
  postTask?: (callback: () => void, options?: { priority?: SchedulerPriority }) => Promise<void>;
  yield?: (options?: { priority?: SchedulerPriority }) => Promise<void>;
}

type SchedulerPriority =
  | "background" // Lowest - non-critical tasks
  | "user-blocking" // Highest - immediate user interactions
  | "user-visible"; // Medium - visual updates

declare global {
  interface Window {
    scheduler?: Scheduler;
  }
}

export const yieldToMain = async (options?: { priority?: SchedulerPriority }) => {
  try {
    // Modern browsers with Scheduler API
    if ("scheduler" in window && window.scheduler) {
      if (window.scheduler.yield) {
        return window.scheduler.yield(options);
      }
      if (window.scheduler.postTask) {
        return window.scheduler.postTask(() => {}, options);
      }
    }

    // Fallback for non-supporting browsers
    return options?.priority === "user-blocking"
      ? Promise.resolve()
      : new Promise(resolve => setTimeout(resolve));
  } catch {
    return new Promise(resolve => setTimeout(resolve));
  }
};
