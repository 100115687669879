import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.async-iterator.for-each.js";
import "core-js/modules/esnext.iterator.for-each.js";
import _defineProperty from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import { useCallback, useMemo, useRef } from "react";
import { sendMirrorCustomEvent } from "shared/components/tracking/mirror";
import { useParsedCookie } from "shared/hooks";
import { isString } from "shared/lib/utils";
import { Edition, editionOptions } from "./data";
export * from "./data";
const isValidEdition = edition => Object.values(Edition).includes(edition);
export function useEdition(option) {
  const {
    update: updateCookie,
    value
  } = useParsedCookie("scmp_edition", raw => raw, _objectSpread(_objectSpread({}, option), {}, {
    expires: 400 // Max value is 400 days
  }));
  const isInitialHasSelected = useRef(value !== null);
  const get = useCallback(() => isString(value) && isValidEdition(value) ? value : Edition.International, [value]);
  const getOptions = useCallback(() => editionOptions, []);
  const update = useCallback(value => {
    if (!isValidEdition(value)) return;
    sendMirrorCustomEvent({
      edition: value,
      event_name: "edition_preference"
    });
    updateCookie(value);
  }, [updateCookie]);
  return {
    get,
    getOptions,
    isInitialHasSelected: isInitialHasSelected.current,
    update
  };
}
export function useEditionValue(option) {
  const {
    get
  } = useEdition(option);
  const value = useMemo(() => get(), [get]);
  return value;
}